import React from "react"
import Layout from "../layout"
import { Link } from "gatsby"

const Conditions = () => {
  return (
    <Layout>
      <div
        style={{
          padding: "3em",
          backgroundColor: "#FAFAFA",
          color: "333",
          maxWidth: "50em",
          textAlign: "left",
        }}
      >
        <h1>Köpvillkor</h1>
        <p>Nedan presenteras våra köpvillkor.</p>
        <h2 className="font-bold text-lg">Avbokningsregler:</h2>
        <ul className="flex flex-col place-items-start gap-2 text-left list-disc p-0 m-0 pb-8">
          <li>
            Avbokning av våra kurser ska ske senast 3 dagar innan kursstart
            annars debiteras du full pris.
          </li>
          <li>
            Avbokning av intensivkurser Bil, MC och Moped ska ske senast två
            veckor innan kursstart för återbetalning av kursavgiften där endast
            administrationsavgiften med 10% dras av. Du får behålla bokpaketet
            och onlinetester.
          </li>
        </ul>

        <p>
          Vid avbokning en vecka innan kursstart får du tillbaka 50 % av
          kursavgiften exklusive administrationsavgiften. Du får behålla
          bokpaketet och onlinetester.
        </p>
        <ul className="flex flex-col place-items-start gap-2 text-left list-disc p-0 m-0 pb-8">
          <li>
            Vid avbokning samma dag som kursstart så debiteras du fullpris om ej
            godtagbar anledning.
          </li>
          <li>
            Har du en körlektion eller kurs och du är sjuk så att du kan inte
            delta så behöver du meddela oss via mejl eller sms till +46723881132
            senaste kl 07:00 på morgonen och för att inte bli debiterad så
            kräver det ett läkarintyg från vårdcentralen som du behöver lämna
            till oss. (Vi accepterar inte online-läkare)
          </li>
        </ul>

        <h2 className="font-bold text-lg">Giltighetstid</h2>
        <p>
          Köpta paket ska utnyttjas inom avsatt giltighetstid vilket är
          följande:
        </p>
        <ul className="flex flex-col place-items-start gap-2 text-left list-disc p-0 m-0 pb-8">
          <li>MC: 1 år.</li>
          <li>Intensivkurs: 3 månader.</li>
          <li>Övriga paket: 6 månader.</li>
        </ul>
      </div>
    </Layout>
  )
}

export default Conditions
